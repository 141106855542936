import { postChangeLanguage } from '@api/POST_ChangeLanguage';
import { useAuth } from '@context/AuthContext';
import { CompanyContext } from '@context/CompanyContext';
import useGetCompanyList from '@hooks/useGetCompanyList';
import { useI18n } from '@hooks/useI18n';
import { useNavigationConfig } from '@hooks/useNavigationConfig';
import { DataMeInterface, LanguageType } from '@interface/UserInterface';
import {
  ColorBlue,
  ColorLight,
  ColorRed,
  Navbar,
  NavbarUserDropdown,
  Sidebar,
  useClickOutside
} from '@uangcermat/uikit-web';
import { getGuardedNavigation } from '@utils/getGuardedNavigation';
import { localStorageService } from '@utils/localStorage';
import { syncRefreshToken } from '@utils/shouldRefreshToken';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import pkgJson from '../../package.json';

const initialUserData: NavbarUserDropdown = {
  name: '',
  avatarUrl: '',
  isShowAccount: false,
  isHoverable: true,
  onClickProfile: () => undefined,
  onClickLogout: () => undefined
};

interface BranchInterface {
  clientId: string;
  id: string;
  name: string;
  teamLeader?: string;
  teamMembersCount?: number;
}
// FIXME: styled should be fixed at uiKit
const NavbarContainerStyled = styled.div`
  ul {
    top: 60px !important;
    z-index: 9;
  }

  > div {
    div[data-test-id='navbarCompany'] {
      margin-left: 16px;
    }
    > div:nth-child(2) > div:first-child {
      display: none;
    }
  }
`;

const companyImgUrl = '/images/navbar-logo.svg';

const appData = {
  appName: process.env.APP_NAME,
  appVersion: pkgJson.version
};

const LayoutStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
`;

const MainStyled = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`;

const SidebarContainerStyled = styled.div`
  min-width: 208px !important;
  // TODO: move this styling to UI kit
  li > div > p {
    font-size: 14px;
    line-height: 16px;
  }
`;

const ContentContainerStyled = styled.div`
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
`;

export default function DashboardLayout({ children }: { children: React.ReactNode }) {
  const { logout, userAuth, setUserAuth } = useAuth();
  const [activeId, setActiveId] = useState('');
  const [listCompany, setListCompany] = useState<Array<BranchInterface>>();
  const { changeLanguage } = useI18n();
  const currentLanguage = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';
  const { setCompany, activeCompanyBranch, setActiveCompanyBranch } = useContext(CompanyContext);

  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutside({
    ref: wrapperRef,
    cb: () => {
      setIsOpen(false);
    }
  });
  const currentActiveCompany = localStorageService.getActiveCompany('active_company') ?? '';
  const handleChangeLanguage = async (to: LanguageType) => {
    await postChangeLanguage({ lang: to });
    await syncRefreshToken();
    changeLanguage(to);
    router.reload();
  };
  const [userData, setUserData] = useState<NavbarUserDropdown>({
    ...initialUserData,
    isHoverable: true,
    onClickLogout: logout
  });

  const { navigationConfig } = useNavigationConfig();

  const router = useRouter();

  const sidebarActiveIdMapper: Record<string, string> = {
    '/activities/general-report': 'activities.generalList',
    '/activities/general-report/detail/[id]': 'activities.generalList',
    '/activities/info-gathering-report': 'activities.infoGatheringList',
    '/activities/info-gathering-report/detail/[id]': 'activities.infoGatheringList',
    '/collection-case/[caseId]': 'collectionCase.index',
    '/collection-case': 'collectionCase.index',
    '/planner': 'activities.planList',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    '/collection-groups': 'group.collectionList',
    '/collection-groups/[groupId]': 'group.collectionList',
    '/collection-groups/[groupId]/add-cases': 'group.collectionList',
    '/collection-groups/[groupId]/remove-cases': 'group.collectionList',
    '/collection-groups/create': 'group.collectionList'
  };

  const { refetch } = useGetCompanyList({
    options: {
      onSuccess: (companyList) => {
        setListCompany(companyList.data);
      },
      refetchOnMount: false,
      enabled: false
    }
  });

  useEffect(() => {
    if (currentActiveCompany) {
      // FIXME: resolve for data changed or removed
      localStorageService.setActiveCompany('active_company', currentActiveCompany);

      listCompany?.filter((company) => {
        if (company.id === currentActiveCompany) {
          setActiveCompanyBranch(company);
        }
      });
    } else {
      if (listCompany && listCompany.length > 0) {
        setActiveCompanyBranch(listCompany[0]);
        localStorageService.setActiveCompany('active_company', listCompany[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCompany, activeCompanyBranch, currentActiveCompany]);

  useEffect(() => {
    const companyId = userAuth?.companies.find(
      (company) => company.id === currentActiveCompany
    )?.id;

    if (companyId) {
      setCompany({
        id: companyId
      });
    }
    return () => {
      setCompany(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompanyBranch, currentActiveCompany, setCompany]);

  useEffect(() => {
    changeLanguage(currentLanguage);
    if (userAuth) refetch();
    return () => {
      localStorage.removeItem('active_company');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  const routeActivityReport = '/activities';

  const changeActiveCompany = useCallback(
    (branchName: BranchInterface) => {
      const company = userAuth?.companies.find((company) => company.name === branchName.name);
      if (company) {
        localStorageService.setActiveCompany('active_company', company.id);
        setActiveCompanyBranch(company);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userAuth]
  );

  const setNavbarData = useCallback(
    (data: DataMeInterface) => {
      setUserData({
        ...userData,
        name: data.name || '',
        avatarUrl: data?.profilePicture || '',
        onClickLogout: logout
      });
      if (!currentActiveCompany) {
        setActiveCompanyBranch(data.companies[0]);
        data.companies[0] && changeActiveCompany(data.companies[0]);
      } else {
        const company = userAuth?.companies.find((company) => company.id === currentActiveCompany);
        if (company) {
          setActiveCompanyBranch(company);
          company?.name && changeActiveCompany(company);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeActiveCompany, logout, setUserAuth, userAuth, userData.isShowAccount]
  );

  useEffect(() => {
    if (userAuth) {
      setNavbarData(userAuth);
    }
  }, [setNavbarData, userAuth]);

  useEffect(() => {
    setActiveId(sidebarActiveIdMapper[router.route]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveId, router]);

  const [isShowBranch, setIsShowBranch] = useState(false);
  const companyBranchSwitcherRef = useRef(null);

  useClickOutside({
    ref: companyBranchSwitcherRef,
    cb: () => {
      setIsShowBranch(false);
    }
  });

  return (
    <>
      <LayoutStyled>
        <NavbarContainerStyled>
          {/* FIXME: navbar company logo style */}
          <Navbar
            appLogoUrl={companyImgUrl}
            bgColor={ColorBlue.darkBlue}
            onClickLogo={() => {
              router.push(routeActivityReport);
              setActiveId(sidebarActiveIdMapper[routeActivityReport]);
            }}
            languageSwitcher={{
              activeLanguage: currentLanguage,
              isOutsideLanguageSwitcher: true,
              hoverColor: ColorBlue.cornFlowerBlue,
              onClickEnglish: async () => {
                if (userAuth) {
                  setUserAuth({
                    ...userAuth,
                    lang: 'en'
                  });
                }
                await handleChangeLanguage('en');
              },
              onClickBahasa: async () => {
                if (userAuth) {
                  setUserAuth({
                    ...userAuth,
                    lang: 'id'
                  });
                }
                await handleChangeLanguage('id');
              }
            }}
            isHideCompany={true}
            company={{
              onClickBranchSwitcher: () => setIsShowBranch((prev) => !prev),
              companyBranchRefElm: companyBranchSwitcherRef,
              isShowBranch: isShowBranch,
              onClickBranch: (value) => {
                router.push(routeActivityReport);
                localStorageService.setActiveCompany('active_company', value.id);
                changeActiveCompany(value);
                setActiveId(sidebarActiveIdMapper[routeActivityReport]);
                setActiveCompanyBranch(value);
                setIsShowBranch(false);
              },
              companyName: activeCompanyBranch?.name ?? '',
              listCompany: listCompany && listCompany?.length > 1 ? listCompany : [],
              logoUrl: '/images/navbar-company-Logo.png',
              hoverColor:
                listCompany && listCompany.length > 1
                  ? ColorBlue.cornFlowerBlue
                  : ColorBlue.darkBlue,
              hoverItemColor: ColorLight.aliceBlue,
              isHoverable: false,
              activeColor: ColorLight.aliceBlue
            }}
            user={{
              ...userData,
              userRefElm: wrapperRef,
              isShowAccount: isOpen,
              onClickProfile: () => {
                setIsOpen((prev) => !prev);
              },
              hoverColor: ColorRed.deepCarmine,
              hoverItemColor: ColorLight.whiteSmoke
            }}
          />
        </NavbarContainerStyled>
        <MainStyled>
          <SidebarContainerStyled>
            {activeId && (
              <Sidebar
                navigationConfig={getGuardedNavigation({
                  navConfig: navigationConfig,
                  userPermission: userAuth?.permissions ?? []
                })}
                onClickNav={(nav) => {
                  setActiveId(nav.id);
                  if (nav.navLink) {
                    router.push(nav.navLink);
                  }
                }}
                activeId={activeId}
                appName={appData.appName}
                appVersion={appData.appVersion}
              />
            )}
          </SidebarContainerStyled>
          <ContentContainerStyled>{children}</ContentContainerStyled>
        </MainStyled>
      </LayoutStyled>
    </>
  );
}
